const queryString = require('query-string');
(function ($, w, d, undefined) {
  d.addEventListener('DOMContentLoaded', () => {
    const mapWrapperEl = d.querySelector('#dealer-map')
    if (mapWrapperEl) {
      initDealerMap(mapWrapperEl)
    }
  })

  d.addEventListener('header.repositioned', () => {
    let mapWrapperEl = d.querySelector('#dealers-map')
    const mapContentWrapperEl = d.querySelector('.dealers-content-wrapper')
    const mq = window.matchMedia('(min-width: 992px)')
    const aroundMeItem = d.querySelector('.department-selector-wrapper .department-list .department-list-item-aroundme')
    if (!mq.matches) {
      const deptSelectorList = $('.department-selector-wrapper .department-list .department-list-item:eq(0)')
      if (deptSelectorList && !aroundMeItem) {
        deptSelectorList.after(`<a href="${ibiza_theme_vars.dealerArchiveUrl}?geoloc=1" class="department-list-item department-list-item-aroundme">${ibiza_theme_vars.aroundMeLabel}</a>`)
      }
    } else if (aroundMeItem) {
      aroundMeItem.remove()
    }

    if (mapWrapperEl) {
      let mapLoaded = false
      if ('geolocation' in navigator && mapWrapperEl.dataset.geolocation) {
        if (!mq.matches) {
          const parsed = queryString.parse(location.search)
          if (parsed.geoloc) {
            navigator.geolocation.getCurrentPosition((position) => {
              // Retrieve dealer around this point
              // Ajax query
              const data = {
                action: 'get_dealers',
                position,
                ibizaNonce: ibizaVars.ibizaNonce
              }

              $.post(ibizaVars.ibizaAjaxUrl, data, (response) => {
                if (response.success) {
                  mapContentWrapperEl.innerHTML = response.content

                  // Refresh element to get filtered places
                  mapWrapperEl = d.querySelector('#dealers-map')
                  initArchiveDealersMap(mapWrapperEl, position)
                  initArchiveDealerMapEvent(mapWrapperEl)
                  mapLoaded = true

                  const event = new Event('map.reloaded') // (2)
                  d.dispatchEvent(event)
                }
              })
            })
          }
        }
      }

      if (!mapLoaded) {
        initArchiveDealersMap(mapWrapperEl)
        initArchiveDealerMapEvent(mapWrapperEl)
      }
    }
  })

  function initDealerMap(mapWrapper) {
    const position = new google.maps.LatLng(mapWrapper.dataset.lat, mapWrapper.dataset.lng)
    const markerBounds = new google.maps.LatLngBounds()
    const infowindow = new google.maps.InfoWindow()

    const map = new google.maps.Map(mapWrapper, {
      center: position,
      zoom: 12
    })

    const place = {
      id: mapWrapper.dataset.post_id,
      post_title: '',
      lat: mapWrapper.dataset.lat,
      lng: mapWrapper.dataset.lng,
      marker_color: '#ba3c8a'
    }

    addMarker(place, map, markerBounds, infowindow, false)
  }

  function initArchiveDealersMap(mapWrapper, geolocCoord = false) {
    const places = JSON.parse(mapWrapper.dataset.places)
    if (geolocCoord) {
      var lat = geolocCoord.coords.latitude
      var lng = geolocCoord.coords.longitude
    } else {
      var lat = places[0].lat
      var lng = places[0].lng
    }
    const position = new google.maps.LatLng(lat, lng)
    const markerBounds = new google.maps.LatLngBounds()
    const infowindow = new google.maps.InfoWindow()

    const map = new google.maps.Map(mapWrapper, {
      center: position,
      zoom: 12
    })

    if (mapWrapper.dataset.places) {
      places.forEach((place) => {
        place.marker_color = '#ba3c8a'
        addMarker(place, map, markerBounds, infowindow)
      })

      if (places.length > 1) {
        map.fitBounds(markerBounds)
        map.panToBounds(markerBounds)
      }
    } else {
      console.log('No places defined')
    }
  }

  function initArchiveDealerMapEvent(mapWrapper) {
    const mqDesktop = window.matchMedia('screen and (min-width:992px)')
    if (mqDesktop.matches) {
      stickyMap()
      d.addEventListener('scroll', stickyMap)
    }

    mqDesktop.addListener((query) => {
      if (mqDesktop.matches) {
        stickyMap()
        d.addEventListener('scroll', stickyMap)
      } else {
        d.removeEventListener('scroll', stickyMap)
        mapWrapper.classList.remove('sticked')
        mapWrapper.classList.remove('sticked-bottom')
      }
    })
  }

  function getMarkerIcon(marker_color) {
    const icon = {
      path: 'M166.947,83.475c0,27.32-13.178,55.415-29.78,80.119c-23.808,35.419-53.695,73.055-53.695,73.055 s-29.887-37.636-53.693-73.055C13.175,138.89,0,110.795,0,83.475C0,37.37,37.374,0,83.472,0C129.573,0,166.947,37.37,166.947,83.475 z M83.472,44.707c-20.856,0-37.767,16.911-37.767,37.768c0,20.859,16.911,37.769,37.767,37.769c20.859,0,37.769-16.91,37.769-37.769C121.241,61.618,104.331,44.707,83.472,44.707z',
      fillColor: marker_color,
      fillOpacity: 0.9,
      strokeColor: 'white',
      scale: 0.18,
      anchor: new google.maps.Point(83, 236), // Fonctionne en pixel à partir de la taille original du SVG
      strokeWeight: 2
    }
    return icon
  }

  function addMarker(place, map, markerBounds, infowindow, markerAnimation = true) {
    const position = new google.maps.LatLng(place.lat, place.lng)
    markerBounds.extend(position)

    const marker = new google.maps.Marker({
      position,
      icon: getMarkerIcon(place.marker_color),
      map,
      title: place.post_title
    })

    if (markerAnimation) {
      const postElementClass = `.dealer-${place.id}`
      if (postElementClass) {
        const dealerEl = document.querySelector(postElementClass)
        dealerEl.addEventListener('mouseenter', () => {
          marker.setAnimation(google.maps.Animation.BOUNCE)
        })

        dealerEl.addEventListener('mouseleave', () => {
          marker.setAnimation(null)
        })
        /*
        directoriesEL.addEventListener('click',(e) => {
          map.panTo(position)
          map.setZoom(12)
        })*/
      }
    }

    if (infowindow) {
      const iwId = `dealer-${place.id}-info`
      const mqDesktop = window.matchMedia('(min-width:992px)')
      let markerClickListener
      // Handle Tablet orientation (Ipad)
      if (mqDesktop.matches) {
        markerClickListener = initMarkerEvent(map, marker, infowindow, place, iwId)
      } else {
        initDealersCarrousel(marker, place.id)
      }
      mqDesktop.addListener((query) => {
        if (query.matches) {
          markerClickListener = initMarkerEvent(map, marker, infowindow, place, iwId)
          destroyDealersCarrousel()
        } else {
          google.maps.event.removeListener(markerClickListener)
          initDealersCarrousel(marker, place.id)
        }
      })
    }
    return marker
  }

  function initMarkerEvent(map, marker, infowindow, place, iwId) {
    return marker.addListener('click', () => {
      infowindow.close()
      infowindow.setContent(`<div id="${iwId}" data-id="${place.id}" class="map-info-window"><i class="fa fa-spinner fa-spin fa-2x"></i></div>`)
      const iwDomReadyListener = infowindow.addListener('domready', () => {
        const infoWindowEl = d.querySelector('.gm-style-iw')
        if (!d.querySelector(`#${iwId}`).classList.contains('loaded')) {
          infoWindowEl.classList.add('opening')
          generateInfoWindow(map, marker, infowindow, place.id, iwId)
          // Remove DomReady Listener to avoid duplication on each click
          google.maps.event.removeListener(iwDomReadyListener)
        }
      })
      infowindow.open(map, marker)
    })
  }


  function generateInfoWindow(map, marker, infowindow, postId, iwId) {
    const postTeaseInnerWrapperEl = document.querySelector(`.dealer-tease.post-${postId} > div`)
    if (postTeaseInnerWrapperEl) {
      const infoWindowEl = d.querySelector('.gm-style-iw')
      infoWindowEl.classList.remove('opening')

      const articleEl = document.createElement('article')
      articleEl.classList.add('entry', 'dealer-post', `dealer-${postId}`, 'style-light')
      articleEl.innerHTML = postTeaseInnerWrapperEl.outerHTML

      const iwContent = `<div id="${iwId}" data-id="${postId}" class="map-info-window loaded">${articleEl.outerHTML}</div>`
      infowindow.setContent(iwContent)
    }
  }

  function initDealersCarrousel(marker, postId) {
    marker.addListener('click', () => {
      const dealerWrapperEl = $('.dealer-posts-wrapper:not(.slick-initialized)')
      const slickedDealerWrapperElement = $('.dealer-posts-wrapper')
      if (dealerWrapperEl.length) {
        $('.dealer-posts-wrapper').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          infinite: true,
          centerMode: true,
          centerPadding: '15%'
        })
      }
      const selectedDealer = $(`.dealer-${postId}`)
      if (selectedDealer.length) {
        if (selectedDealer.data('slide-index') !== false) {
          slickedDealerWrapperElement.slick('slickGoTo', selectedDealer.data('slide-index'))
        }
      }
    })
  }

  function destroyDealersCarrousel() {
    const dealerWrapperEl = $('.dealer-posts-wrapper.slick-initialized')
    if (dealerWrapperEl) {
      dealerWrapperEl.slick('unslick')
    }
  }

  function stickyMap() {
    const headerEl = document.querySelector('#header')
    const footerEl = document.querySelector('footer')
    const mapEl = document.querySelector('#dealers-map')

    const mapWrapperEl = document.querySelector('.dealers-map-wrapper')
    const contentWrapperEl = document.querySelector('.dealers-content-wrapper')
    const adminbar = document.querySelector('#wpadminbar')

    let adminbarHeight = 0
    if (adminbar) {
      adminbarHeight = adminbar.offsetHeight
    }

    let headerOffsetTop = headerEl.offsetHeight
    const bottomScreen = window.pageYOffset + window.innerHeight - adminbarHeight
    const wrapperHeight = window.innerHeight - headerOffsetTop - adminbarHeight


    let enableSticky = true
    mapEl.style.width = `${mapWrapperEl.offsetWidth}px`

    if (footerEl.offsetTop - (contentWrapperEl.offsetTop + headerOffsetTop) < wrapperHeight) {
      enableSticky = false
      // mapEl.style.height = `${contentWrapperEl.offsetHeight}px`
    } else {
      // mapEl.style.height = `${wrapperHeight}px`
    }

    // contentWrapper.style.minHeight = `${wrapperHeight}px`
    //

    if (window.pageYOffset > contentWrapperEl.offsetTop && footerEl.offsetTop > bottomScreen && enableSticky) {
      if (!mapEl.classList.contains('sticked')) {
        headerOffsetTop += adminbarHeight
        mapEl.style.top = `${headerOffsetTop}px`
        mapEl.classList.add('sticked')
      }
      if (mapEl.classList.contains('sticked-bottom')) {
        mapEl.classList.remove('sticked-bottom')
      }
    } else if (footerEl.offsetTop < bottomScreen && enableSticky) {
      if (mapEl.classList.contains('sticked')) {
        mapEl.classList.remove('sticked')
        mapEl.style.top = ''
      }
      if (!mapEl.classList.contains('sticked-bottom')) {
        mapEl.classList.add('sticked-bottom')
      }
    } else if (window.pageYOffset < contentWrapperEl.offsetTop) {
      if (mapEl.classList.contains('sticked')) {
        mapEl.classList.remove('sticked')
        mapEl.style.top = ''
      }
      if (mapEl.classList.contains('sticked-bottom')) {
        mapEl.classList.remove('sticked-bottom')
      }
    }
  }
}($, window, document))
